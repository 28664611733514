import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import device from '../device.js'

const PageContent = ({ className, content }) => (
  <div className={className}>
    <Container>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  </div>
)

export default styled(PageContent)`
  padding: 60px 20px 100px;

  p, li {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    max-width: 800px;

    @media ${device.mobileM} {
      font-size: 18px;
    }

    @media ${device.tablet} {
      font-size: 20px;
      line-height: 2;
    }
  }

  p, ul, ol {
    margin-bottom: 40px;
  }

  @media ${device.tablet} {
    padding: 100px 0;
  }
`;

      